import React from "react"
import { Link } from "gatsby"
import Headroom from "react-headroom"
import logo from "../images/forty-icon.svg" // Tell webpack this JS file uses this image
import '../styles/header.css'


const Header = () => {
  return (
    <div>
      
      <Headroom >
      <div id="barra-nav">
      <div className="nav-container">
    <div className="brand">
    <Link to="/" title="Homepage"><img src={logo} alt="Logo" /></Link>
    </div>
    <nav>
      <div className="nav-mobile"><a id="nav-toggle"><span></span></a></div>
      <ul className="nav-list">
     
        <li>
        <Link to="/work/" title="Our Work" style={{ textDecoration: 'none' }}> <span className="menu-label gt-regular black margin-span">Work</span></Link>
        </li>
        <li>
        <Link to="/studio/" title="Our Studio" style={{ textDecoration: 'none' }}><span className="menu-label gt-regular black margin-span">Studio</span></Link> 
</li>
            <li>
            <Link to="/services/" title="Our Services" style={{ textDecoration: 'none' }}><span className="menu-label gt-regular black">Services</span></Link> 

            </li>
          
          
          </ul>
        
       
   
       
     
    </nav>
  </div></div>
   
   
   
    {/* <div id="barra-nav" class="fadeout">
             <div id="wrapper-nav">
                 <div id="header-leftcol" class="col-navbar">
                 <Link to="/"><img src={logo} alt="Logo" /></Link>
 
                 </div>
                 <div id="header-centercol" class="col-navbar">
                 </div>
                 <div id="header-rightcol" class="col-navbar">
                 <Link to="/work" style={{ textDecoration: 'none' }}> <span class="menu-label gt-regular black margin-span">Work</span></Link> 
                 <Link to="/studio" style={{ textDecoration: 'none' }}><span class="menu-label gt-regular black margin-span">Studio</span></Link> 
                    <Link to="/services" style={{ textDecoration: 'none' }}><span class="menu-label gt-regular black">Services</span></Link> 

                 </div>
                 
         
             </div>
         </div> */}
    </Headroom>
    </div>
    
  )

}


export default Header
