import React from 'react'
import { checkPropTypes } from 'prop-types'
import Footer from '../components/footer'
import Header from '../components/header'
import SEO from '../components/seo'


import $ from 'jquery/dist/jquery'
import '../styles/styles.css'
import '../styles/utilitary.css'




const Layout = (props) => {

     
    return( 

        <div>
                    <SEO
       
        pagePath="/"
        schema={`{
          "@context": "http://schema.org/",
          "@type": "Organization",
          "name": "Forty Degrees - Creative Studio",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Rua António da Rocha Madaíl, nº47, Loja 6",
            "addressLocality": "Aveiro",
            "addressRegion": "Portugal",
            "postalCode": "3800-509"
          },
          "telephone": "912090596"
        }`}
      />

           <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"></link>
            <Header />
            {props.children}
            
            <Footer />
        </div>
    )
}

export default Layout

