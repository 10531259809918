import React from "react"
import { Link } from "gatsby"

const Footer = () => {
    return(
        <footer>
              <div id="footer-section">
        <div id="footer-container">
        <div id="logo-footer" className="center">
            <p className="inmind gt-light"> Do you have a project in mind?</p>
            <a href="mailto:geral@fortydegrees.pt?bcc=nuno@fortydegrees.pt&subject=Hello,%20I%20have%20a%20project%20in%20mind" target="_blank"><button className="btn-next gt-regular white ">Let's talk!</button></a>

        </div>
        <div id="navigation-footer" className="nav-footer gt-light">
            <div className="col-20 center col">
            <Link to="/work" title="Our work" style={{ textDecoration: 'none' }}><span className="item-footer">Work</span> </Link>
            </div>
            <div className="col-20 center col">
            <Link to="/studio" title="Our studio" style={{ textDecoration: 'none' }}> <span className="item-footer">Studio</span> </Link>
            </div>
            <div className="col-20 center col">
            <Link to="/services" title="Our services" style={{ textDecoration: 'none' }}> <span className="item-footer">Services</span> </Link>
            </div>
            <div className="col-20 center col">
                <span className="item-footer"><a className="no-decoration" title="Facebook" target="_blank" href="https://www.facebook.com/FortyDegreesStudio/">Facebook</a></span>
            </div>
            <div className="col-20 center col">
                <span className="item-footer"><a className="no-decoration" title="Instagram" target="_blank" href="https://www.instagram.com/fortydegreesstudio/">Instagram</a></span>
            </div>
        </div>
        <div id="bottom-footer2" className="center gt-light">

            <p>geral@fortydegrees.pt </p><p> (+351) 912 090 596 </p>
        </div>
        <div id="bottom-footer" className="center gt-light">
            <p>Handcrafted by 40º | All rights reserved © 2020</p>
        </div>
    </div>
    
    </div>

        </footer>
    )


}

export default Footer