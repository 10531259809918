import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import ogimagem from "../images/metaImage.png" 
function SEO({ description, lang, meta, title, pageUrl, image, post, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title || site.siteMetadata.title
  const metaUrl = pageUrl || site.siteMetadata.siteUrl

  console.log(image);

  return (
    <Helmet
      defer={false}
      htmlAttributes={{
        lang,
      }}
      defaultTitle={metaTitle}
      titleTemplate={`%s`}
      meta={meta}
    >
      {/* General tags */}
      <title>{metaTitle}</title>
      <meta name="image" content={ogimagem} />
      <meta name="description" content={metaDescription} />
      <meta name="title" content={metaTitle} />
      <div itemscope itemtype="http://schema.org/Organization">
    <span itemprop="name">Forty Degrees - Creative Studio</span>
    <div itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
        <span itemprop="streetAddress">Rua António da Rocha Madaíl, 47, Loja 6</span>
        <span itemprop="addressLocality">Aveiro</span>
        <span itemprop="addressRegion">Portugal</span> 
        <span itemprop="postalCode">3800-509</span>
    </div>
    Phone: <span itemprop="telephone">912090596</span>
</div>

      {/* OpenGraph tags */}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:type" content={post ? `article` : `website`} />
      <meta property="og:url" content={metaUrl} />
      <meta property="og:image" content={ogimagem} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:site_name" content={metaTitle} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="{site.siteMetadata.author}" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:image" content={ogimagem} />
      <meta name="twitter:description" content={metaDescription} />

      {children}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
}

export default SEO